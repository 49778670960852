import React from 'react';
import PropTypes from 'prop-types';

import HeaderSection from '../../components/HeaderSection';
import AboutCesSection from '../../components/AboutCesSection';
import TeamMemberCard from '../../components/TeamMemberCard';
import AboutUsCardManager from '../../components/AboutUsCardManager';

const AboutUsTemplate = ({
  header,
  aboutCesSection,
  aboutJanCarstens,
  team,
  partners
}) => (
  <>
    <HeaderSection {...header} />
    <AboutCesSection {...aboutCesSection} />
    <div className="border border-black-100 md:container mx-auto max-w-md" />
    <TeamMemberCard {...aboutJanCarstens} />
    <AboutUsCardManager {...{ team, partners }} />
  </>
);

AboutUsTemplate.propTypes = {
  header: PropTypes.object.isRequired,
  aboutCesSection: PropTypes.object.isRequired,
  aboutJanCarstens: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
  partners: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired
};

export default AboutUsTemplate;
