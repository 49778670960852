import React from 'react';
import PropTypes from 'prop-types';

import { PageRoutes } from '../../data/constants';

import Layout from '../../components/Layout';

import AboutUsTemplate from '../../templates/AboutUs';

const AboutUs = ({ pageContext, data }) => {
  const { language } = pageContext;
  const {
    markdownRemark: { frontmatter }
  } = data;

  const { pageTitle, ...pageContent } = frontmatter;

  return (
    <Layout
      language={language}
      activePage={PageRoutes.ABOUT_US}
      pageTitle={pageTitle}
    >
      <AboutUsTemplate {...{ ...pageContent, language }} />
    </Layout>
  );
};

AboutUs.propTypes = {
  pageContext: PropTypes.shape({
    language: PropTypes.string.isRequired
  }).isRequired,
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        pageTitle: PropTypes.string.isRequired,
        header: PropTypes.object.isRequired,
        aboutCesSection: PropTypes.object.isRequired,
        aboutJanCarstens: PropTypes.object.isRequired,
        team: PropTypes.object.isRequired,
        partners: PropTypes.object.isRequired
      }).isRequired
    }).isRequired
  }).isRequired
};

export default AboutUs;
