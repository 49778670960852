import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import Image from '../Image';

const TeamMemberCard = ({
  title,
  subtitle,
  text,
  textExtended,
  buttonText: { more, less },
  profileImage
}) => {
  const [showMore, setShowMore] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);
  const textExtendedRef = useRef(null);

  useEffect(() => {
    if (textExtendedRef && textExtendedRef.current) {
      setMaxHeight(textExtendedRef.current.scrollHeight);
    }
  });

  const toggleShowMore = () => setShowMore(!showMore);

  return (
    <section>
      <div className="px-4 py-8">
        <div className="md:container mx-auto  p-4 transition-all duration-500 overflow-hidden max-w-md bg-white-100 shadow-100">
          <div className="flex md:space-x-8 flex-col space-y-4 md:space-y-0 md:flex-row">
            <div style={{ minWidth: '300px' }} className="w-full">
              <Image
                image={profileImage}
                alt="Jan Carstens"
                className="object-cover w-full h-full"
              />
            </div>
            <div className="space-y-4 text-black-200">
              <div className="space-y-2">
                <h4 className="card-title-sm sm:card-title">{title}</h4>
                <p className="card-text-sm sm:card-text">{subtitle}</p>
              </div>
              <p className="card-text-sm sm:card-text">{text}</p>
              <button
                className="bg-primary-300 py-2 px-8 rounded-full text-white-100 flex space-x-2 focus:outline-none items-center mx-auto md:mx-0"
                onClick={toggleShowMore}
              >
                <p className="cta-text-light-sm sm:cta-text-light">
                  {showMore ? less : more}
                </p>
                {showMore ? <ExpandLess /> : <ExpandMore />}
              </button>
            </div>
          </div>
          <p
            className={`h-full transition-all duration-500 overflow-hidden card-text-sm sm:card-text text-black-200 ${
              showMore ? 'mt-8' : ''
            }`}
            style={{ maxHeight: showMore ? `${maxHeight}px` : '0px' }}
            ref={textExtendedRef}
            dangerouslySetInnerHTML={{ __html: textExtended }}
          />
        </div>
      </div>
    </section>
  );
};

TeamMemberCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  textExtended: PropTypes.string.isRequired,
  buttonText: PropTypes.shape({
    more: PropTypes.string.isRequired,
    less: PropTypes.string.isRequired
  }).isRequired,
  profileImage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired
      }).isRequired
    })
  ]).isRequired
};

export default TeamMemberCard;
