import React from 'react';
import PropTypes from 'prop-types';

import Image from '../Image';

const AboutUsCard = ({ title, text, backgroundImage }) => (
  <div className="bg-white-100 shadow-100 p-4 flex-1 space-y-8">
    <div style={{ maxHeight: '250px' }} className="overflow-hidden">
      <Image
        image={backgroundImage}
        alt={title}
        className="w-full object-cover object-center"
      />
    </div>
    <div>
      <h4 className="card-title-sm sm:card-title text-primary-300">{title}</h4>
      <p className="card-text-sm sm:card-text text-black-100">{text}</p>
    </div>
  </div>
);

AboutUsCard.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  backgroundImage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired
      }).isRequired
    })
  ]).isRequired
};

export default AboutUsCard;
