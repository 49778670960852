import React from 'react';
import PropTypes from 'prop-types';

import AboutUsCard from '../AboutUsCard';

const AboutUsCardManager = ({ team, partners }) => (
  <section className="pb-8 px-4">
    <div className="md:container mx-auto flex md:space-x-8 flex-col space-y-8 md:space-y-0 max-w-md md:flex-row">
      <AboutUsCard {...team} />
      <AboutUsCard {...partners} />
    </div>
  </section>
);

AboutUsCardManager.propTypes = {
  team: PropTypes.object.isRequired,
  partners: PropTypes.object.isRequired
};

export default AboutUsCardManager;
