import React from 'react';
import PropTypes from 'prop-types';

import SectionTitleWrapper from '../SectionTitleWrapper';
import Image from '../Image';

const AboutCesSection = ({ title, subtitle, text, quote, backgroundImage }) => {
  const { text: quoteText, author } = quote;

  return (
    <SectionTitleWrapper title={title} subtitle={subtitle}>
      <div className="px-4">
        <div className="container mx-auto space-y-8">
          <div className="flex md:space-x-8 flex-col-reverse items-center p-4 shadow-100 max-w-md mx-auto md:flex-row md:max-w-full md:shadow-none">
            <div className="pt-4 md:pt-0">
              <p
                className="card-text-sm sm:card-text text-black-200"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </div>
            <div className="h-auto md:width-40 w-full">
              <Image
                alt="girl touching on transparent display "
                className="object-cover w-full"
                image={backgroundImage}
              />
            </div>
          </div>
          <div className="flex flex-col items-center space-y-4">
            <p className="text-center quote-text-sm sm:quote-text max-w-lg text-black-200">
              &quot;{quoteText}&quot;
            </p>
            <p className="sm:quote-author-text quote-author-text-sm text-center text-black-200">
              - {author}
            </p>
          </div>
        </div>
      </div>
    </SectionTitleWrapper>
  );
};

AboutCesSection.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  quote: PropTypes.shape({
    text: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired
  }).isRequired,
  backgroundImage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired
      }).isRequired
    })
  ]).isRequired
};

export default AboutCesSection;
