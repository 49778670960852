import { graphql } from 'gatsby';

import AboutUs from './template';

export const pageQuery = graphql`
  query AboutUsTemplate($language: String) {
    markdownRemark(
      frontmatter: {
        templateKey: { eq: "AboutUs" }
        language: { eq: $language }
      }
    ) {
      frontmatter {
        pageTitle
        header {
          title
          text
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 3000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        aboutCesSection {
          title
          subtitle
          text
          quote {
            text
            author
          }
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 6000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        aboutJanCarstens {
          title
          subtitle
          text
          profileImage {
            childImageSharp {
              fluid(maxWidth: 5000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          buttonText {
            more
            less
          }
          textExtended
        }
        team {
          title
          text
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        partners {
          title
          text
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

export default AboutUs;
