import React from 'react';
import PropTypes from 'prop-types';

const SectionTitleWrapper = ({ children, title, subtitle }) => (
  <section className="py-8 flex flex-col space-y-8 sm:py-12 sm:space-y-12">
    <div className="flex flex-col items-center space-y-1">
      <h2 className="section-heading-sm text-primary-300 sm:section-heading text-center">
        {title}
      </h2>
      <h4 className="section-subtitle-sm text-black-100 sm:section-subtitle text-center">
        {subtitle}
      </h4>
    </div>
    {children}
  </section>
);

SectionTitleWrapper.defaultProps = {
  children: null
};

SectionTitleWrapper.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
};

export default SectionTitleWrapper;
