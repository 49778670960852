import React from 'react';
import PropTypes from 'prop-types';

import Image from '../Image';

const HeaderSection = ({ title, text, backgroundImage }) => (
  <section className="relative py-16 px-4 sm:py-24 mt-12">
    <div className="absolute top-0 left-0 w-full h-full">
      <Image
        image={backgroundImage}
        className="object-cover w-full h-full"
        alt="section background that represents current page"
      />
    </div>
    <div className="container mx-auto relative space-y-2 px-4">
      <h2 className="section-heading-sm sm:section-heading text-white-100">
        {title}
      </h2>
      <p
        className="card-text-sm sm:card-text text-white-200 max-w-2xl"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  </section>
);

HeaderSection.defaultProps = {
  text: ''
};

HeaderSection.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
  backgroundImage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired
      }).isRequired
    })
  ]).isRequired
};

export default HeaderSection;
